const meta = {
  // Metadata
  siteTitle: 'Aman Seelay',
  siteDescription:
    'Product designer based in Chandigarh, India with experience in delivering end-to-end UX/UI design for digital products.',
  siteTitleAlt: 'Seelay',
  siteShortName: 'Seelay',
  siteUrl: 'https://www.seelay.in', // No trailing slash!
};

const social = {
  siteLogo: `src/static/s-logo-red-maskable.svg`,
  siteBanner: `${meta.siteUrl}/images/social-banner.jpg`,
  twitter: '@imseelay',
};

const website = {
  ...meta,
  ...social,
  disqusShortName: 'seelay',
  googleAnalyticsID: 'G-V5JQ7XNJBY',
  // Manifest
  themeColor: '#FF2222',
  backgroundColor: '#FFFFFF',
};

module.exports = website;
